<template>
  <div v-if="user" class="dashboard default--text">
    <category-block
      v-if="!drawer"
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <h1 v-if="showTitle">{{ `Ciao ${user.firstName}` }}</h1>
    <h6 v-if="showSubtitle" class="mb-4 mb-sm-0">
      {{ $t("dashboard.subtitle") }}
    </h6>
    <v-row class="mt-6 pa-2 white rounded-md align-center">
      <v-col cols="12" md="4">
        <div class="flipCard">
          <div class="boxes">
            <div class="box front">
              <img :src="fidelityImg" class="w-100 h-100" />
            </div>
            <div class="box back pa-4" style="background-color:#FFA300;">
              <barcode v-bind:value="fidelityEan" class="fidelity-ean">
                Error
              </barcode>
            </div>
          </div>
        </div>
      </v-col>
      <!-- <v-col
        class="fidelity-card"
        cols="12"
        md="4"
        v-if="showCard && user.fidelityCard"
        @click="flipped = !flipped"
      >
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img :src="fidelityImg" class="w-100 h-100" />
            </div>
            <div
              class="flip-card-back back primary rounded-lg d-flex justify-center align-center"
            >
              <div class="rounded-md" :class="{ expanded: expandedBarcode }">
                <barcode v-bind:value="fidelityEan" class="fidelity-ean">
                  Error
                </barcode>
              </div>
            </div>
          </div>
        </div>
      </v-col> -->
      <v-col
        cols="12"
        md="4"
        class="fidelity-card-container"
        v-if="user.fidelityCard && user.fidelityCard.length > 0"
      >
        <div class="text-h2 primary--text pa-0 mb-2">
          {{ $t("dashboard.fidelityCard.title") }}
        </div>
        <div class="text-h4 text-uppercase pa-0 card-number-title">
          {{ $t("dashboard.cardNumber") }}
        </div>
        <div class="text-h2 fidelity-card-number pa-0 mb-2">
          {{ user.fidelityCard }}
        </div>
        <span
          v-if="fidelityCardType === 'CARTA_VANTAGGI_PIU'"
          class="fidelity-card-bonus-container pa-1 mb-2 row d-flex align-center"
        >
          <v-col cols="3" class="mr-n3">
            <v-icon color="primary" :size="$vuetify.breakpoint.xs ? 30 : 25"
              >$vault</v-icon
            >
          </v-col>
          <v-col cols="9">
            <div class="vault-title">
              {{ $t("dashboard.fidelityCard.bonusTitle") }}
            </div>
            <div class="vault-value" v-if="moneyCheckout">
              {{ $n(moneyCheckout, "currency") }}
            </div>
            <div class="vault-value" v-else>{{ $n(0, "currency") }}</div>
          </v-col>
        </span>
        <small>
          {{ $t("dashboard.fidelityCard.latestUpdate") }}
          <span class="update-time">{{ $dayjs().format("DD/MM/YYYY") }}</span>
        </small>
      </v-col>
      <v-col cols="12" md="4">
        <v-row class="d-flex justify-center align-center dashboard-infos">
          <v-col cols="12" class="dashboard-last-name">
            <strong>{{ $t("dashboard.infos.names") }}</strong>
            {{ user.firstName }} {{ user.lastName }}
          </v-col>
          <!-- <v-col cols="12">
            <strong>{{ $t("dashboard.infos.address") }}</strong>
            {{ userAddress }}
          </v-col> -->
          <v-col cols="12" class="dashboard-city">
            <strong>{{ $t("dashboard.infos.city") }}</strong>
            {{ user.billingAddress.city }}
          </v-col>
          <v-col cols="12" class="dashboard-email">
            <strong>{{ $t("dashboard.infos.email") }}</strong> {{ user.email }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row v-if="$vuetify.breakpoint.xs" class="my-6 pa-0">
      <v-col cols="12" v-for="(card, idx) in cards" :key="idx">
        <v-card
          class="mobile-dashboard-card pa-1 d-flex flex-column align-center justify-center rounded-md"
          :to="{ name: card.routeName }"
        >
          <div class="card-icon mb-2 d-flex justify-center align-center">
            <v-icon color="primary" large v-if="card.icon">{{
              `$${card.icon}`
            }}</v-icon>
          </div>
          <v-card-title class="pa-0">
            {{ $t(card.title) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card
          @click.stop="doLogout"
          elevation="0"
          class="py-3 my-2 white d-flex d-logout"
        >
          <v-card-title class="pa-0">
            <v-icon color="black" class="mx-3" large>
              $doorOpen
            </v-icon>
            {{ $t("profile.menu.logout") }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row class="my-6 pa-0">
      <template v-for="(card, idx) in cards">
        <v-col :key="idx" v-if="card.active" cols="12" md="3">
          <DashboardCard
            :navigation="card.navigation"
            :cardData="card"
            :drawer="drawer"
          />
        </v-col>
      </template>
    </v-row>
    <v-row
      class="my-6 pa-2 white rounded-md"
      v-if="$vuetify.breakpoint.smAndUp && orders && orders.length > 0"
    >
      <v-col cols="12" class="d-flex flex-column">
        <div class="orders-container generic-list">
          <OrdersTable
            :orders="orders"
            @delete="deleteOrder"
            @addAllToCart="addAllToCart"
            @editOrder="editOrder"
          ></OrdersTable>
        </div>
        <v-btn
          elevation="0"
          large
          color="primary"
          class="ml-auto rounded-pill mt-4 mb-2 text-"
          :to="{ name: 'Orders' }"
          >{{ $t("dashboard.orders.link") }}</v-btn
        >
      </v-col>
    </v-row>
    <category-block
      v-if="!drawer"
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />

    <div
      v-if="$platform_is_cordova && isBetaTester"
      class="mt-10 development-link front-weight-bold"
    >
      <a :href="developmentLink">Apri versione di test</a>
    </div>
  </div>
</template>
<style lang="scss">
.flipCard {
  perspective: 1000px;
  max-width: 320px;
}

.flipCard .boxes {
  border-radius: 12px;
  display: grid;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
  transform-style: preserve-3d;
}
.flipCard .boxes .box {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flipCard .boxes .box.front {
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--v-grey-base);
  transform: rotateX(0deg);
}
.flipCard .boxes .box.back {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--v-grey-base);
  transform: rotateY(-180deg);
}
.flipCard:hover .boxes {
  transform: rotateY(-180deg);
}
.fidelity-card {
  .image {
    width: 100%;
    height: auto;
  }
}
.flip-card {
  background-color: transparent;
  border-radius: 12px;
  width: 318px;
  max-width: 318px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  border-radius: 12px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--v-grey-base);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.fidelity-card-container {
  .card-number-title {
    line-height: 1rem !important;
  }
  small {
    font-size: 12px !important;
  }
}
.fidelity-card-bonus-container {
  background: #f8f9fa;
  width: 250px !important;
  max-width: 250px;
  border-radius: 6px;
  i {
    font-size: 42px;
  }
  .vault-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -4px;
  }
  .vault-value {
    font-size: 22px;
    font-weight: 600;
  }
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
.dashboard {
  h1 {
    font-weight: 600;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    h6 {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .fidelityPoints {
    background: transparent linear-gradient(180deg, #e1e9e0 0%, #179dd9 100%) 0%
      0% no-repeat padding-box;
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: var(--v-primary-base);
        font-weight: bold;
      }
    }
  }
  .donateTree,
  .donatePoints {
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: $white;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    a,
    .v-icon {
      color: $white !important;
    }
  }

  .donateTree {
    background-image: url("/img_layout/menu/card/sostenibilita/dona-un-albero.png");
    background-size: cover;
  }
  .donatePoints {
    background-color: #da1b5c;
  }
  .socioCard {
    cursor: pointer;
    height: 200px;
    width: auto;
    border-radius: 8px;
    background: transparent linear-gradient(180deg, #e1e9e0 0%, #179dd9 100%) 0%
      0% no-repeat padding-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 50px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-items: center;
  }

  .barcode {
    grid-area: 2 / 2 / 3 / 3;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: $white;
    display: flex;
    transition: 500ms;
    .fidelity-ean {
      width: 100%;
      height: 100%;
      max-height: 116px;
      .vue-barcode-element {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
  .barcode.expanded {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    z-index: 1;
    transition: 500ms;
    .fidelity-ean {
      max-height: fit-content;
    }
  }
  .user {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 13px;
    font-weight: bold;
  }
  .logo {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
    img {
      max-width: 150px;
    }
  }
  .desc {
    grid-area: 2 / 1 / 3 / 2;
    text-transform: uppercase;
  }
}
</style>
<script>
// @ is an alias to /src
import DashboardCard from "@/components/profile/DashboardCard.vue";

import categoryMixin from "~/mixins/category";
import pointsMixins from "@/mixins/pointsMixins";

// import get from "lodash/get";
import { mapGetters } from "vuex";
import OrdersTable from "@/components/orders/OrdersTable.vue";
import reload from "~/mixins/reload";
import OrderService from "~/service/orderService";
import UserService from "@/commons/service/userService";
import AnalyticsService from "~/service/analyticsService";
import { getPersonInfo } from "~/service/ebsn";

export default {
  name: "Dashboard",
  components: { DashboardCard, OrdersTable },
  mixins: [categoryMixin, pointsMixins, reload],
  props: {
    drawer: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    showSubtitle: { type: Boolean, default: true },
    showCard: { type: Boolean, default: true }
  },
  data() {
    return {
      expandedBarcode: false,
      cards: null,
      orders: null,
      flipped: false,
      fidelityCardType: null,
      defaultCards: [
        // {
        //   active: true,
        //   name: "fidelityPoints",
        //   title: "dashboard.fidelityPoints.title",
        //   description: "",
        //   label: "dashboard.fidelityPoints.label",
        //   icon: "",
        //   navigation: true,
        //   routeName: "#",
        //   class: "fidelityPoints",
        //   link: "dashboard.fidelityPoints.link"
        // },
        // {
        //   active: false,
        //   isPolverization: true,
        //   name: "pointsPolv",
        //   title: "dashboard.pointsPolv.title",
        //   description: "dashboard.pointsPolv.description",
        //   icon: "plus",
        //   navigation: true,
        //   routeName: "PointsPolv",
        //   class: "points-polv",
        //   link: "dashboard.pointsPolv.link"
        // },
        // {
        //   active: true,
        //   name: "news",
        //   title: "dashboard.news.title",
        //   description: "dashboard.news.description",
        //   icon: "bellLight",
        //   navigation: true,
        //   routeName: "NewsListPage",
        //   link: "dashboard.orders.link"
        // },
        {
          active: true,
          name: "profile",
          title: "dashboard.profile.title",
          description: "dashboard.profile.description",
          icon: "profile",
          navigation: true,
          routeName: "ProfileUpdate",
          link: "dashboard.profile.link"
        },
        {
          active: true,
          name: "orders",
          title: "dashboard.orders.title",
          description: "dashboard.orders.description",
          icon: "cart",
          navigation: true,
          routeName: "Orders",
          link: "dashboard.orders.link"
        },
        {
          active: true,
          name: "lists",
          title: "dashboard.lists.title",
          description: "dashboard.lists.description",
          icon: "listCheck",
          navigation: true,
          routeName: "Lists",
          link: "dashboard.lists.link"
        },
        {
          active: true,
          name: "addresses",
          title: "dashboard.addresses.title",
          description: "dashboard.addresses.description",
          icon: "location",
          navigation: true,
          routeName: "Addresses",
          link: "dashboard.addresses.link"
        },
        {
          active: true,
          name: "cards",
          title: "dashboard.cards.title",
          description: "dashboard.cards.description",
          icon: "creditCard",
          navigation: true,
          routeName: "MyPaymentMethods",
          link: "dashboard.cards.link",
          class: "d-flex d-sm-none"
        },
        {
          active: true,
          name: "cards",
          title: "dashboard.vantaggiPiu.title",
          description: "dashboard.vantaggiPiu.description",
          icon: null,
          svg: "/img_layout/iper_isologo.svg",
          navigation: true,
          routeName: null,
          href: "/vantaggi-piu",
          class: "d-flex d-sm-none"
        },
        {
          active: true,
          name: "cards",
          title: "dashboard.logout.title",
          icon: "doorOpen",
          navigation: false,
          class: "d-flex d-sm-none"
        }
        // {
        //   active: true,
        //   name: "orderRefunds",
        //   title: "dashboard.orderRefunds.title",
        //   description: "dashboard.orderRefunds.description",
        //   icon: "pagamento",
        //   navigation: true,
        //   routeName: "Refunds",
        //   link: "dashboard.orderRefunds.link"
        // },
        // {
        //   active: true,
        //   name: "paymentMethods",
        //   title: "dashboard.paymentMethods.title",
        //   description: "dashboard.paymentMethods.description",
        //   icon: "wallet",
        //   navigation: true,
        //   routeName: "MyPaymentMethods",
        //   link: "dashboard.paymentMethods.link"
        // },
        // {
        //   active: true,
        //   name: "gifts",
        //   title: "dashboard.gifts.title",
        //   description: "dashboard.gifts.description",
        //   icon: "vantaggi",
        //   navigation: true,
        //   routeName: "ProfileGift",
        //   link: "dashboard.gifts.link"
        // },
        // {
        // {
        //   active: true,
        //   name: "fidelity",
        //   title: "dashboard.fidelity.title",
        //   description: "dashboard.fidelity.description",
        //   icon: "card",
        //   navigation: true,
        //   routeName: "Fidelity",
        //   class: "fidelity",
        //   link: "dashboard.fidelity.link"
        // },
        // {
        //   active: true,
        //   name: "billing",
        //   title: "dashboard.billing.title",
        //   description: "dashboard.billing.description",
        //   icon: "invoice",
        //   navigation: true,
        //   routeName: "InvoiceData",
        //   class: "billing",
        //   link: "dashboard.billing.link"
        // },
        // {
        //   active: true,
        //   name: "invite",
        //   title: "dashboard.invite.title",
        //   description: "dashboard.invite.description",
        //   icon: "addUser",
        //   navigation: true,
        //   routeName: "Invite",
        //   class: "invite",
        //   link: "dashboard.Invite.link"
        // },
        // {
        //   active: true,
        //   name: "logout",
        //   title: "dashboard.logout.title",
        //   description: "",
        //   icon: "logout",
        //   routeName: "",
        //   navigation: false,
        //   link: ""
        // }
      ]
    };
  },
  async mounted() {
    this.cards = this.defaultCards; // dev purposes
    // this.cards = get(
    //   this.getApplicationConfig,
    //   "DASHBOARD_CONFIG.cards",
    //   this.defaultCards
    // );
    await UserService.getUserDetail();
    await this.reload();
    this.fidelityCardType = getPersonInfo(this.user.person, 100);
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester",
      getApplicationConfig: "custom/getApplicationConfig",
      user: "cart/getUser"
    }),
    userAddress() {
      const userAddr = this.user.billingAddress.address1;
      return userAddr === ""
        ? this.$t("dashboard.missingUserAddress")
        : userAddr;
    },
    fidelityEan() {
      return this.user.fidelityCard.toString().padStart(13, "0");
    },
    fidelityImg() {
      const fidelityCardType = getPersonInfo(this.user.person, 100);
      let fidelityImg = "/img_layout/fidelities/";
      if (fidelityCardType === "CARTA_VANTAGGI") {
        fidelityImg += "advantage-card.png";
      } else if (fidelityCardType === "CARTA_VANTAGGI_PIU") {
        fidelityImg += "advantage-card-plus.png";
      } else {
        // fallback to standard
        fidelityImg += "advantage-card.png";
      }
      return fidelityImg;
    },
    developmentLink() {
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    reload() {
      this.loading = true;
      try {
        OrderService.getOrders(
          this.pageFilter,
          process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
          true
        ).then(data => {
          this.orders = data.orders.slice(0, 5);
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    editOrder(orderId) {
      OrderService.editOrder(orderId).then(() => {
        this.loadCart();

        this.$router.push({
          name: "Checkout"
        });
        // this.fetchOrder(orderId);
        // this.orders = data.orders;
        // // this.page = data.page;
        // this.orderPager = data.page;
      });
    },
    async addAllToCart(orderId) {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let res = await global.vm.$dialog.confirm({
              text: global.vm.$t("message.addAllFromOrder")
            });
            if (res) {
              await this.addProductsFromOrder(orderId);
            }
          }
        }
      }
    }
  }
};
</script>
