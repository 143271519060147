<template>
  <v-card
    v-if="navigation"
    :class="cardData.class"
    elevation="3"
    class="dashboard-card px-2 py-3 d-flex flex-column align-center justify-center rounded-md"
    v-bind="cardLink"
  >
    <div class="card-icon d-flex justify-center align-center">
      <v-icon color="primary" large v-if="cardData.icon">{{
        `$${cardData.icon}`
      }}</v-icon>
      <img v-if="cardData.svg" :src="cardData.svg" height="28" />
    </div>
    <v-card-title class="pa-0">
      {{ $t(cardData.title) }}
    </v-card-title>
    <v-icon
      v-if="$vuetify.breakpoint.xs"
      color="default"
      size="15"
      class="ml-auto pr-2"
      >$next</v-icon
    >
  </v-card>
  <v-card
    v-else
    @click.stop="doLogout"
    :class="cardData.class"
    elevation="0"
    class="py-3 my-2 transparent d-flex d-logout"
  >
    <v-card-title class="pa-0">
      <v-icon color="primary" class="mx-3" large v-if="cardData.icon">{{
        `$${cardData.icon}`
      }}</v-icon>
      {{ $t(cardData.title) }}
    </v-card-title>
  </v-card>
</template>

<style scoped lang="scss">
.v-card {
  height: 135px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: inherit;
    flex-direction: row !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
  // border-radius: 8px;
  // border: 1px solid var(--v-grey-lighten2);
  // background-color: var(--v-grey-lighten2);
  .v-card__title {
    font-size: 16px;
    font-weight: 600;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 14px;
    }
  }
  .v-card__subtitle {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      display: none;
    }
  }
  .description {
    font-size: 14px;
  }
  .v-card__actions {
    position: absolute;
    bottom: 4px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      bottom: 6px;
    }
    right: 1px;
    a {
      color: var(--v-primary-base);
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .card-icon {
    height: 62px;
    width: 62px;
    background: var(--v-primary-lighten2);
    padding: 20px;
    border-radius: 50% !important;
    .v-card__title {
      font-size: 13px !important;
      font-weight: 600;
    }
    @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
      margin-right: 16px;
      padding: 0;
      padding-left: 13px;
    }
    i {
      font-size: 34px !important;
      @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
        font-size: 20px !important;
        margin: 0 auto;
      }
    }
    @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
      height: 43px;
      width: 43px;
      min-height: unset !important;
      padding-right: 12px !important;
    }
  }
}
.invite {
  background-color: white;
  border-color: $primary;
}
.drawer {
  .v-card {
    height: 65px;
    font-weight: 600;
  }
}
.is-cordova {
  .d-logout {
    height: 65px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 24px !important;
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  name: "DashboardCard",
  props: {
    cardData: { type: Object, required: true },
    navigation: { type: Boolean, required: true },
    drawer: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  },
  computed: {
    cardLink() {
      if (this.cardData.routeName) {
        return { to: { name: this.cardData.routeName } }; // Use :to for named routes
      } else if (this.cardData.href) {
        return { href: this.cardData.href }; // Use href for external or internal links
      } else {
        return {}; // Return an empty object if neither is available
      }
    }
  }
};
</script>
